import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import Section from '@latitude/section';
import { Link } from '@latitude/link';
import { Heading2 } from '@latitude/heading';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';

import Layout from '@/components/layout';
import AchPageHeaderSection from './_ach-page-header';
import DegreesCardMicrositeHeader from '../_28d-microsite-header';

const buttonHref = '/credit-cards/28-degrees/addcard/ach-form/';

function AchAdditionalLandingPage(props) {
  const [urlButton, setUrlButton] = useState(null);

  function fetchButtonHref() {
    if (window?.location?.search) {
      const queryParameters = new URLSearchParams(window.location.search);
      const applicationId = queryParameters.get('applicationid');

      if (applicationId) {
        return buttonHref + '?applicationid=' + applicationId;
      }
    }
    return null;
  }

  useEffect(() => {
    const href = fetchButtonHref();
    setUrlButton(href);
  }, []);

  return (
    <Layout location={props.location}>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 0 !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }

          div[class^='MicrositeHeader__SecondBar'].microsite-header__nav-wrap {
            margin-top: 8px;
          }
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/28-degrees/addcard/ach/"
          />
          <meta
            name="description"
            content="Welcome to the Latitude 28° Global Platinum Mastercard® family"
          />
          <title>Additional Card Holder Welcome | 28 Degrees card</title>
        </Helmet>
        <DegreesCardMicrositeHeader />

        <AchPageHeaderSection />
        <Section>
          <Heading2 css={{ color: '#0046aa' }}>
            Welcome to the Latitude 28&deg; Global Platinum Mastercard&reg;
            family
          </Heading2>

          <div className="row">
            <div className="col-md-8">
              <p>
                Simply complete the online form to confirm your personal details
                such as full name, address and date of birth. You can use
                documents such as:
              </p>
              <p>
                <ul>
                  <li>Australian Driver's Licence</li>
                  <li>Australian Passport</li>
                  <li>Medicare card</li>
                </ul>
              </p>

              <p>
                Once complete your new Latitude 28&deg; Global Platinum
                Mastercard will be sent to the account holders address within
                5-7 working days.
              </p>
              <p>
                Latitude 28&deg; Global Platinum Mastercard&reg; is a credit
                facility provided by Latitude Finance Australia ABN 42 008 583
                588 Australian Credit Licence number 392145.
              </p>
            </div>
            <div className="col-md-4">
              {urlButton ? (
                <Link
                  button={BUTTON_STYLE.SECONDARY}
                  css={{
                    margin: '0 auto',
                    width: 'fit-content'
                  }}
                  href={urlButton}
                >
                  Confirm your details
                </Link>
              ) : (
                <div
                  css={{
                    backgroundColor: '#777',
                    color: '#fff',
                    borderRadius: '4px',
                    padding: '12px 24px',
                    margin: '0 auto',
                    width: 'fit-content',
                    textAlign: 'center'
                  }}
                >
                  Missing Application ID
                </div>
              )}
            </div>
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export default AchAdditionalLandingPage;
